<template>
  <Layout>
    <template #default>
      <div class="container-fluid m-0 p-0">
        <div class="row bg-white justify-content-center pt-5">
          <span class="Abschnitt-titel2 t-center">Softwarelösungen</span>
        </div>
        <div class="row pt-5 bg-white justify-content-center">
          <div class="col-4 t-center p-4">
            <hr />
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center bildschirnansicht">
          <div class="col-10 p-0 whi t-center">
            <h2>
              INDIVIDUALSOFTWARE VON <span class="Pillar">PILLAR</span
              ><span class="Soft">SOFT</span>
            </h2>
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center handyansicht">
          <div class="col-10 p-0 whi">
            <h2>
              INDIVIDUALSOFTWARE VON <span class="Pillar">PILLAR</span
              ><span class="Soft">SOFT</span>
            </h2>
          </div>
        </div>

        <div class="row bg-white pt-5 pb-5 justify-content-center bildschirnansicht">
          <div class="col-3 hintergrundHell p-4">
            <div class="row">
              <div class="col">
                <h5>Sie benutzen Standardsoftware in Ihrem Unternehmen?</h5>
                <p>Dann wird Ihnen dies bekannt vorkommen:</p>
                <ul>
                  <li>Ihre Software ist langsam</li>
                  <li>kaum flexibel, Anpassungen unmöglich oder unbezahlbar</li>
                  <li>regelmäßige Lizenzgebühren</li>
                  <li>anonymer Support</li>
                  <li>
                    Sie richten sich nach der Software, und nicht die Software nach Ihnen
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col t-center">
                <button
                  type="button"
                  class="btn btn-outline-dark p-3"
                  @click="$router.push('/Kontakt')"
                >
                  <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
                </button>
              </div>
            </div>
          </div>
          <div class="col-2 t-center"></div>
          <div class="col-3 hintergrundHell p-4">
            <div class="row">
              <div class="col">
                <h5>Sie benötigen eine neue Software für Ihr Unternehmen?</h5>
                <p>Dann wird Ihnen dies bekannt vorkommen:</p>
                <ul>
                  <li>Etwas wirklich passendes gibt es nicht</li>
                  <li>nötige Anpassungen sind nicht möglich</li>
                  <li>einen großen Teil der Software brauchen Sie gar nicht</li>
                  <li>Hohe Anschaffungskosten und laufende Lizenzgebühren</li>
                  <li>Einarbeitungszeiten und Schulungskosten sind nicht kalkulierbar</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col t-center">
                <button
                  type="button"
                  class="btn btn-outline-dark p-3"
                  @click="$router.push('/Kontakt')"
                >
                  <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-white pt-5 pb-5 justify-content-center handyansicht">
          <div class="col-12 hintergrundHell p-3">
            <div class="row">
              <div class="col">
                <h5>Sie benutzen Standardsoftware in Ihrem Unternehmen?</h5>
                <p>Dann wird Ihnen dies bekannt vorkommen:</p>
                <ul>
                  <li>Ihre Software ist langsam</li>
                  <li>kaum flexibel, Anpassungen unmöglich oder unbezahlbar</li>
                  <li>regelmäßige Lizenzgebühren</li>
                  <li>anonymer Support</li>
                  <li>
                    Sie richten sich nach der Software und nicht die Software nach Ihnen
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col t-center">
                <button
                  type="button"
                  class="btn btn-outline-dark p-3"
                  @click="$router.push('/Kontakt')"
                >
                  <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row bg-white  justify-content-center handyansicht">
          <div class="col-12 hintergrundHell p-3 pb-5">
            <div class="row">
              <div class="col">
                <h5>Sie benötigen eine neue Software für Ihr Unternehmen?</h5>
                <p>Dann wird Ihnen dies bekannt vorkommen:</p>
                <ul>
                  <li>Etwas wirklich passendes gibt es nicht</li>
                  <li>nötige Anpassungen sind nicht möglich</li>
                  <li>einen großen Teil der Software brauchen Sie gar nicht</li>
                  <li>Hohe Anschaffungskosten und laufende Lizenzgebühren</li>
                  <li>Einarbeitungszeiten und Schulungskosten sind nicht kalkulierbar</li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col t-center">
                <button
                  type="button"
                  class="btn btn-outline-dark p-3"
                  @click="$router.push('/Kontakt')"
                >
                  <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row hintergrundSchwarz pt-5 justify-content-center ">
          <div class="col-6">
            <div class="row justify-content-center">
              <span class="Abschnitt-titel t-center">Projekte</span>
            </div>
          </div>
          <div class="row pt-5 justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
        </div>
        <div
          class="row hintergrundSchwarz pt-5 pb-5 justify-content-center "
        >
          <div class="col-10 col-md-6  p-0">
            <h5 class="pb-4">Gemeinsam mit Ihnen erarbeiten wir einen Bedarfsplan.</h5>
            <p class="pb-3">
              In jeder Phase des Projektes sind Sie auf dem Laufenden und können jederzeit
              Korrekturen vornehmen.
            </p>
            <p class="pb-3">
              Wir kümmern uns um Ihr Projekt, vom ersten Entwurf bis zur Inbetriebnahme
              Ihrer eigenen Software.
            </p>
            <p class="pb-3">Spätere Erweiterungen der Software sind jederzeit möglich.</p>
            <p class="pb-3">Wir übernehmen die Einarbeitung Ihrer Mitarbeiter.</p>
            <p class="pb-3">
              Als lokaler Ansprechpartner sind wir immer vor Ort schnell für Sie
              erreichbar.
            </p>
            <p class="pb-3">
              Sie haben einen persönlich Ansprechpartner, mit dem Sie jedes Problem
              besprechen können.
            </p>
          </div>
        </div>
        <div class="row hintergrundSchwarz pb-5 pt-5 justify-content-center">
          <div class="col-8 whi t-center">
            <button class="btn btn-outline-danger" @click="$router.push('/Kontakt')">
              Jetzt Kontakt aufnehmen
            </button>
          </div>
        </div>
        

        

        <div class="row hintergrundHell pt-5 justify-content-center">
          <span class="Abschnitt-titel2 t-center">Partner</span>
        </div>
        <div class="row pt-5 hintergrundHell justify-content-center">
          <div class="col-4 t-center p-4">
            <hr />
          </div>
        </div>

        <div class="row hintergrundHell pt-5 justify-content-center ">
          <div class="col-md-8 col-12 hintergrundHell p-5">
            <h5 class="pb-2">Warum wir der richtige Partner für Sie sind?</h5>
            <ul>
              <li>Über 20 Jahre Erfahrung in Projektmanagment von Softwarelösungen.</li>
              <li>
                Erfahrungen aus nationalen und internationalen Projekten, von ganz Klein
                bis richtig Gross.
              </li>
              <li>
                Langjährige Erfahrungen mit Datenbankapplikationen, Webprojekten und
                Individual-Software.
              </li>
              <li>Hier vor Ort und kurzfristig für Sie erreichbar.</li>
            </ul>
          </div>
        </div>
        
        <div class="row hintergrundHell pb-5 pt-5 justify-content-center">
          <div class="col-8 whi t-center">
            <button class="btn btn-outline-danger" @click="$router.push('/Kontakt')">
              Jetzt Kontakt aufnehmen
            </button>
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center bildschirnansicht">
          <div class="col-4 t-center border">
            <img src="../assets/man.jpg" class="img-fluid mx-auto d-block" />
          </div>
          <div class="col-4 p-3 whi">
            <h2>ZUM KOSTENLOSEN BERATUNGSGESPRÄCH</h2>
            <p>Haben Sie Fragen?</p>
            <p>
              Wollen Sie mehr erfahren oder benötigen Sie einen fachkundigen Rat zu Ihrem
              Softwareprojekt? Wir helfen Ihnen von Anfang an.
            </p>
            <p>Ihr persönlicher Ansprechpartner:</p>
            <p>
              Jörg Pillar <br />Tel: +49 173 68 39 084 <br />Mail: info@pillarsoft.de
            </p>
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center handyansicht">
          <div class="col-12 t-center border">
            <img src="../assets/man.jpg" class="img-fluid mx-auto d-block" />
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center handyansicht"></div>
        <div class="row bg-white pt-5 justify-content-center handyansicht">
          <div class="col-10 p-3 whi">
            <h2>ZUM KOSTENLOSEN BERATUNGSGESPRÄCH</h2>
            <p>Haben Sie Fragen?</p>
            <p>
              Wollen Sie mehr erfahren oder benötigen Sie einen fachkundigen Rat zu Ihrem
              Softwareprojekt? Wir helfen Ihnen von Anfang an.
            </p>
            <p>Ihr persönlicher Ansprechpartner:</p>
            <p>
              Jörg Pillar <br />Tel: +49 173 68 39 084 <br />Mail: .de
            </p>
          </div>
        </div>

        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i class="far fa-envelope-open fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px">
                  info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
