<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0 ">
          <div class="row bg-white justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Onlineshops</span>
          </div>
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-md-6 col-sm-12 t-center">
              <img src="../assets/pc-1595125_1920.jpg" class="img-fluid mx-auto d-block" /> 
            </div>
          </div>
          <div class="row bg-white pt-5 justify-content-center">
            <div class="col-md-8 col-sm-10 fs-5 fw-bolder">
              Der Traum vom eigenen Onlineshop
            </div>
          </div>
          <div class="row bg-white pt-5 justify-content-center">
            <div class="col-md-8 col-sm-10 whi">
              <p>
                Der Webshop ist das Schaufenster der Unternehmen, die online
                ihre Produkte und Dienstleistungen anbieten.
              </p>
              <p>
                Warum dies unbedingt über den eigenen Webshop passieren sollte
                und nicht etwa über andere Handelskanäle, lässt sich schnell mit
                Blick auf den Umsatz erklären.
              </p>
              <p>
                Wer sein Produkt beispielsweise über Amazon oder Ebay anbietet,
                hat zwar mehr potentielle Kunden vor sich, doch die
                Handelsriesen verlangen erhebliche Gebühren und das schmälert
                den Gewinn beträchtlich.
              </p>
              <p>
                Auch werden Sie massiv in der Präsentation Ihrer Artikel
                bevormundet. Dort sind Sie einer von vielen.
              </p>
            </div>
          </div>

          <div class="row bg-white pb-5 t-center">
            <div class="col-12 p-5 whi">
             <button
                type="button"
                class="btn btn-outline-dark p-3"
                @click="$router.push('/Kontakt')"
              ><h2 class="fw-bolder">
                WIR helfen Ihnen!</h2>
              </button>
            </div>
          </div>

          <div class="row hintergrundSchwarz justify-content-center pt-5">
            <span class="Abschnitt-titel t-center">Vorteile</span>
          </div>
          <div class="row pt-5 hintergrundSchwarz justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          <div class="row hintergrundSchwarz pt-5 justify-content-center">
            <div class="col-md-8 col-sm-10 ">
              <p>
                Ein eigener Webshop ist die kostengünstige Variante Umsatz zu
                generieren.
              </p>
              <p>
                Egal, ob Sie den Webshop als einzigen Vertriebsweg wählen, oder
                als Erweiterung zu Ihrem stationären Handel.
              </p>
              <p>
                Moderne Shopsysteme unterstützen Sie hierbei bei allen
                notwendigen Punkten innerhalb Ihres Webshops.
              </p>
              <p>
                Je nach Anforderung, setzten wir unterschiedliche Shopsystem ein,
                um Ihren Onlineshop zu erstellen.
              </p>
              <p>
                Schnittstellen ermöglichen die Anbindung Ihres Warenwirtschftsystems.
              </p>
              
            </div>
          </div>
          <div class="row hintergrundSchwarz pb-5 t-center">
            <div class="col-12 p-5 ">
             <button
                type="button"
                class="btn btn-outline-danger p-3"
                @click="$router.push('/Kontakt')"
              ><h2 class="fw-bolder">
                WIR helfen Ihnen!</h2>
              </button>
            </div>
          </div>
         
          <div class="row hintergrundHell justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Unser Service</span>
          </div>
          <div class="row pt-5 hintergrundHell justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          <div class="row hintergrundHell pt-5 pb-5 justify-content-center">
            <div class="col-md-4 col-sm-10  whi">
              
              <h4 class="pb-3">Wir beraten Sie bei folgenden Punkten</h4>
              <ul>
                <li>Auswahl des für Sie passenden Shopsystems</li>
                <li>Aufbau der Artikelgruppen</li>
                <li>Versand und Bezahlmöglichkeiten</li>
                <li>Notwendige Angaben zu Preisen, Gewichten usw.</li>
                <li>Produktfotografie und Präsentation</li>
                <li>Marketing und Werbung für Ihren Webshop</li>
                <li>Layout und Anpassung an Ihr Firmendesign</li>
              </ul>
            </div>
            <div class="col-md-4 col-sm-12 p-0 whi">
              <img src="../assets/ecommerce.jpg" class="img-fluid mx-auto d-block" />    
            </div>
            
          </div>

          <div class="row bg-white pt-5 justify-content-center">
            <div class="col-md-4 col-sm-10 t-center ">
              <img src="../assets/man.jpg" class="img-fluid mx-auto d-block" />    
            </div>
            <div class="col-md-4 col-sm-10 p-3 whi">
              <h4 class="pb-3">ZUM KOSTENLOSEN BERATUNGSGESPRÄCH</h4>
              <p>Haben Sie Fragen?</p>
              <p>
                Wollen Sie mehr erfahren oder benötigen Sie einen fachkundigen
                Rat zu Ihrem Softwareprojekt? Wir helfen Ihnen von Anfang an.
              </p>
              <p>Ihr persönlicher Ansprechpartner:</p>
              <p>
                Jörg Pillar <br/>Tel: +49 173 68 39 084 <br/>Mail:
                info@pillarsoft.de
              </p>
            </div>
          </div>
        </div>
        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i
                  class="far fa-envelope-open fa-2x"
                  style="font-size: 1.3em"
                ></i>
                <span
                  style="height: 70px; padding-top: 10px; margin-top: -10px"
                >
                  info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
