<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0">
          <div class="row bg-white justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Kontaktformular</span>
          </div>
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          <div class="contact_form bg-white pb-5">
            <div class="row justify-content-center">
              <div class="col-md-8 pt-3">
                
                <div class="row justify-content-center whi">
                  <div class="col-md-4"></div>
                  <div class="col-md-6">
                    <span class="required_notification"
                      >* notwendige Eingaben</span
                    >
                  </div>
                </div>
                <div class="row justify-content-center whi">
                  <div class="col-md-8">
                    <p>
                      Hier können Sie ganz einfach mit uns den Kontakt
                      aufnehmen.
                    </p>
                    <p>
                      Wir melden uns so schnell wie möglich bei Ihnen zurück.
                      Wenn Sie uns Ihre Telefonnummer verraten, auch auf diesem
                      Weg.
                    </p>
                  </div>
                </div>
                <div class="row justify-content-center whi">
                  <div class="col-md-8">
                    <ul>
                      <li>
                        <label class="textLabel" for="name">Name:*</label>
                        <input
                          type="text"
                          name="name"
                          class="formtextField"
                          v-bind:class="background.name"
                          placeholder="Mein Name"
                          v-model="mail.name"
                          
                        />
                        
                      </li>
                      <li>
                        <label class="textLabel" for="email">Email:*</label>
                        <input
                          type="text"
                          name="email"
                          class="formtextField"
                          placeholder="max@example.de"
                          v-model="mail.email"
                          v-bind:class="background.email"
                          
                        />
                      </li>

                      <li>
                        <label class="textLabel" for="email">Telefon:</label>
                        <input
                          type="text"
                          name="telefon"
                          class="formtextField"
                          placeholder="0123 456789"
                          v-model="mail.telefon"
                        />
                      </li>

                      <li>
                        <label class="textLabel" for="email">Interesse:</label>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            name="datenschutz"
                            class="checkbox"
                            id="interesseSoftware"
                            v-model="mail.Software"
                          />
                          <label class="form-check-label" for="inlineCheckbox1"
                            >Software</label
                          >
                        </div>

                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            name="datenschutz"
                            class="checkbox"
                            id="interesseWebdesign"
                            v-model="mail.Webdesign"
                          />
                          <label class="form-check-label" for="inlineCheckbox2"
                            >Webdesign</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            name="datenschutz"
                            class="checkbox"
                            id="interesseOnlineShop"
                            v-model="mail.Onlineshop"
                          />
                          <label class="form-check-label" for="inlineCheckbox3"
                            >Onlineshop</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            name="datenschutz"
                            class="checkbox"
                            id="interesseSonstiges"
                            v-model="mail.Sonstigem"
                          />
                          <label class="form-check-label" for="inlineCheckbox3"
                            >Sonstiges</label
                          >
                        </div>
                      </li>

                      <li>
                        <label class="textLabel" for="email">Message:*</label>
                        <textarea
                          name="message"
                          cols="160"
                          rows="5"
                          v-model="mail.message"
                          v-bind:class="background.message"
                          
                        >
                        </textarea>
                      </li>

                      <li>
                        <input
                          type="checkbox"
                          name="datenschutz"
                          class="checkbox"
                          v-model="mail.datenschutz"
                          v-bind:class="background.datenschutz"
                          
                        />*<span
                          class="ps-5 checkboxLabel"
                          v-bind:class="background.datenschutz"
                          for="email"
                        >
                          Die Angaben zum  <router-link to="/Datenschutz" class="">Datenschutz</router-link>
                          habe ich gelesen .
                        </span>
                      </li>
                      <li>
                        {{ mail.gesendet }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            <div class="row justify-content-center whi">
              <div class="col-md-4  d-grid gap-2">
                <button
                  class="submit m-0"
                  type="submit"
                  @click="sendMail()"
                >
                  Submit Form
                </button>
              </div>
            </div>
            </div>
            

          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import { mapGetters } from "vuex";

import axios from "axios";

export default {
  name: "Start",

  data() {
    return {
      newSite: null,
      visi: false,
      mail: {
        name: "",
        email: "",
        message: "",
        Software: "",
        Webdesign: "",
        Onlineshop: "",
        Sonstigem: "",
        datenschutz: false,
        gesendet: "",
      },
      background: {
        name: "",
        email: "",
        message: "",
        datenschutz: "",
      },
    };
  },
  components: {
    Layout,
  },

  methods: {
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }
      if (this.mail.message == "") {
        this.background.message = "border border-2 border-danger";
        re = false;
      } else {
        this.background.message = "border border-2 border-success";
      }
      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "info@pillarsoft.de",
          name: this.mail.name,
          email: this.mail.email,
          text: this.mail.message,
          Software: this.mail.Software,
          Webdesign: this.mail.Webdesign,
          Onlineshop: this.mail.Onlineshop,
          datenschutz: this.mail.datenschutz,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://pillar.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 2000);
    },
    

    ifAktuell() {
      if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
};
</script>
<style scoped>
</style>
