<template>
<div>  
  <carousel :items-to-show="4" :wrap-around="true" :autoplay="3000">
    <slide v-for="slide in slides" :key="slide">
      <img  class="img-fluid"     
          :src="getImgUrl(slide)"           
      />
          </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes

import "../eigen_css/carouselHeader.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  methods: {
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.jpg$/);
      console.log(images);
      return images("./" + pet + ".jpg");
    },
  },
  data() {
    const slides = [      
      "cerf8",
      "cerf7",
      "cerf6",
      "cerf5",
      "cerf4",
      "cerf3",
      "cerf2",
      "cerf1",
      "cerf9",
      "cerf10",
      "cerf11",
      "cerf12",
      "cerf13",
      "cert14",
      "cert15",

    ];
    return {
      slides,
    };
  },
};
</script>
