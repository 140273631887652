<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0 ">
          <div class="row bg-white justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Certifikate</span>
          </div>
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-6 t-center p-4">
              <h1> Unserer Unternehmen ist Mitglied</h1>
            </div>
          </div>
          <div class="row pt-1 bg-white justify-content-center">
            <div class="col-1 t-center p-4">
              <img src="../assets/Unternehmernetzwerk-cyan.png" class="img-fluid mx-auto d-block" />
              
            </div>
            <div class="col-1 t-center p-4">
              <img src="../assets/logo150_2.png" class="img-fluid mx-auto d-block" />
              
            </div>
          </div>
          

          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-6 t-center p-4">
              <h1> Unsere langjährige Erfahrung</h1>
            </div>
          </div>

          <div class="row bg-white pt-5 justify-content-center">
            <div class="col-md-8 col-sm-10 whi">
              <p class="fw-bolder">
               Keine andere Branche ist so schnelllebig wie die Informatik.
              </p>
              <p>
                Deswegen ist es um so wichtiger sich stets und ständig über neue Techniken, Trends oder auch gesetzliche Grundlagen zu informieren.
              </p>
              <p>
                Wir verbringen einen erheblichen Teil unserer Zeit mit Weiterbildung, damit wir Ihnen immer den möglichst neusten Stand bieten können.
              </p>
              
            </div>
          </div>

          <div class="row bg-white pb-5 justifyCenter">
            <div class="col-8 p-0 whi ">
             <carouselCertifikate></carouselCertifikate>
            </div>
          </div>

          
        </div>
        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i
                  class="far fa-envelope-open fa-2x"
                  style="font-size: 1.3em"
                ></i>
                <span
                  style="height: 70px; padding-top: 10px; margin-top: -10px"
                >
                info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
import carouselCertifikate from "../components/carouselCertifikate.vue";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
    carouselCertifikate,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
