<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0 bgFoto">
          <div class="row justify-content-center">
            <div class="col-7 DES">
              <h1>Datenschutz</h1>
            </div>
          </div>

          <div class="row bg-white justifyCenter">
            <div class="col-8 fs-6 p-4">
              <div class="">
                <h3 class="title">Geltungsbereich</h3>
                <p>
                  Diese Datenschutzerklärung klärt Nutzer über die Art, den
                  Umfang und Zwecke der Erhebung und Verwendung
                  personenbezogener Daten durch den verantwortlichen Anbieter
                  J.Pillar auf dieser Website (im folgenden "Angebot") auf.
                </p>
                <p>
                  Die rechtlichen Grundlagen des Datenschutzes finden sich im
                  Bundesdatenschutzgesetz (BDSG), dem Telemediengesetz (TMG),
                  dem Telekommunikationsgesetz (TKG), der
                  Datenschutz-Grundverordnung der EU (DSGVO) und dem Gesetz zur
                  Erhöhung der Sicherheit informationstechnischer Systeme
                  (ITSiG).
                </p>
                <h3 class="title">SSL-Verschlüsselung</h3>
                <p>
                  Website-Betreiber sind nach "§ 13 TMG dazu verpflichtet
                  "[...], soweit dies technisch möglich und wirtschaftliche
                  zumutbar ist, im Rahmen ihrer jeweiligen Verantwortlichkeit
                  für geschäftsmäßige angebotene Telemedien durch technische und
                  organisatorische Vorkehrungen sicherzustellen, dass [...] die
                  genutzten techischen Einrichtungen [...] gegen Verletzung des
                  Schutzes personenbezogener Daten [...] gesichert sind". Die
                  Übertragung von Daten von und zu dieser Webseite sind mit
                  einem SSL-Zertifkat geschützt, aus Gründen der Kompatibilität
                  wird jedoch auch eine unverschlüsselte Verbindung angeboten.
                  Sollte Ihr Browser nicht automatisch die verschlüsselte
                  Verbindung nutzen, so können Sie unter
                  <a href="https://">diesem Link</a> manuell die Verschlüsselung
                  aktivieren.
                </p>
                <p>
                  Einige Client-Programme führen eine Gültigkeitsprüfung für
                  Zertifikate durch. Das ermöglicht der Certificate Authority
                  (dem austellenden Unternehmen) nachzuvollziehen, welche
                  Domains von Ihrer IP aus besucht wurden. Derartige Mechanismen
                  liegen weit außerhalb des Einflussbereiches des Anbieters.
                </p>
                <h3 class="title">Zugriffsdaten / Server-Logfiles</h3>
                <p>
                  Der Anbieter (beziehungsweise sein Webspace-Provider) erhebt
                  Daten über jeden Zugriff auf das Angebot (so genannte
                  Serverlogfiles). Zu den Zugriffsdaten gehören:
                </p>
                <ul>
                  <li>Name der abgerufenen Webseite / Datei</li>
                  <li>Datum und Uhrzeit des Abrufs</li>
                  <li>Übertragene Datenmenge</li>
                  <li>Meldung über erfolgreichen Abruf</li>
                  <li>Browsertyp nebst Version</li>
                  <li>das Betriebssystem des Nutzers</li>
                  <li>Referrer URL (die zuvor besuchte Seite)</li>
                  <li>IP-Adresse und der anfragende Provider</li>
                </ul>
                <p>
                  Der Anbieter verwendet die Protokolldaten nur für statistische
                  Auswertungen zum Zweck des Betriebs, der Sicherheit und der
                  Optimierung des Angebotes. Der Anbieterbehält sich jedoch vor,
                  die Protokolldaten nachträglich zu überprüfen, wenn aufgrund
                  konkreter Anhaltspunkte der berechtigte Verdacht einer
                  rechtswidrigen Nutzung besteht. Durch die Logfiles gewinnen
                  wir zum anderen Erkenntnisse darüber, wie unser Dienst genutzt
                  wird. So erfahren wir zum Beispiel, welche Seiten besonders
                  beliebt sind. Die Ergebnisse dieser Analyse liegen am Ende in
                  statistischer Form vor und sind anonym. Wir verwenden
                  IP-Adressen nicht, um Nutzer zu identifizieren. IP-Adressen
                  speichern wir über einen Zeitraum von maximal vierzehn Tagen.
                </p>
                <p>
                  Wir setzen auf den Logfiles basierende
                  Einbruchserkennungssysteme (IDS) ein, welche bei mutmaßlich
                  illegaler oder unsachgemäßer Nutzung des Angebotes die
                  IP-Adresse und damit verbundene Daten über diesen Zeitraum
                  hinaus speichern können.
                </p>
                <h3 class="title">Umgang mit personenbezogenen Daten</h3>
                <p>
                  Personenbezogene Daten sind Informationen, mit deren Hilfe
                  eine Person bestimmbar ist, also Angaben, die zurück zu einer
                  Person verfolgt werden können. Dazu gehören die IP, der Name,
                  die E-Mail-Adresse oder die Telefonnummer, aber auch Daten
                  über Vorlieben, Hobbies, Mitgliedschaften oder welche
                  Webseiten von jemandem angesehen wurden zählen zu
                  personenbezogenen Daten.
                </p>
                <p>
                  Personenbezogene Daten werden von dem Anbieter nur dann
                  erhoben, genutzt und weiter gegeben, wenn der Nutzer dem nicht
                  widersprochen hat (OptOut). Dritte Parteien werden nachfolgend
                  einzeln genannt. Sie können unser Online-Angebot grundsätzlich
                  ohne Offenlegung Ihrer Identität nutzen. Es unterliegt Ihrer
                  freien Entscheidung, ob Sie Ihre Identität für personalisierte
                  Dienste preisgeben. Ihre Angaben speichern wir auf besonders
                  geschützten Servern in Deutschland. Der Zugriff auf die Daten
                  ist nur wenigen besonders befugten und unterwiesenen Personen
                  möglich, die mit der technischen, kaufmännischen oder
                  redaktionellen Betreuung der Server oder des Angebotes befasst
                  sind. Nicht anonymisierte Daten werden nur so lange
                  gespeichert, wie dies für eine eventuelle Kontaktaufnahme
                  nötig ist oder wir durch gesetzliche Aufbewahrungspflichten,
                  etwa zu steuerlichen oder buchhalterischen Zwecken, dazu
                  verpflichtet sind.
                </p>
                <h3 class="title">Verwendung von Webfonts</h3>
                <p>
                  Auf diesen Internetseiten werden externe Schriften, Google
                  Fonts verwendet. Google Fonts ist ein Dienst der Google Inc.
                  ("Google"). Die Einbindung dieser Web Fonts erfolgt durch
                  einen Serveraufruf, in der Regel ein Server von Google in den
                  USA. Hierdurch wird an den Server übermittelt, welche unserer
                  Internetseiten Sie besucht haben. Auch wird die IP-Adresse des
                  Browsers des Endgerätes des Besuchers dieser Internetseiten
                  von Google gespeichert. Nähere Informationen finden Sie in den
                  Datenschutzhinweisen von Google, die Sie hier abrufen
                  können:<br />
                  <a
                    href="https://www.google.com/fonts#AboutPlace:about"
                    rel="nofollow"
                    target="_blank"
                    >www.google.com/fonts#AboutPlace:about</a
                  ><br />
                  <a
                    href="https://www.google.com/policies/privacy/"
                    rel="nofollow"
                    target="_blank"
                    >www.google.com/policies/privacy/</a
                  >
                </p>
                <h3 class="title">Verwendung von Font Awesome</h3>
                <p>
                  Auf diesen Internetseiten wird Font Awesome verwendet, welches
                  über den Anbieter BootstrapCDN eingebunden ist. Die Einbindung
                  dieser Schriftart erfolgt durch einen Serveraufruf, in der
                  Regel ein Server in den USA. Hierdurch wird an den Server
                  übermittelt, welche unserer Internetseiten Sie besucht haben.
                  Auch wird die IP-Adresse des Browsers des Endgerätes des
                  Besuchers dieser Internetseiten von BootstrapCDN gespeichert.
                  Nähere Informationen finden Sie in den Datenschutzhinweisen
                  von BootstrapCDN, die Sie hier abrufen können:<br />
                  <a
                    href="http://fontawesome.io/"
                    rel="nofollow"
                    target="_blank"
                    >fontawesome.io/</a
                  ><br />
                  <a
                    href="https://www.bootstrapcdn.com/privacy-policy/"
                    rel="nofollow"
                    target="_blank"
                    >www.bootstrapcdn.com/privacy-policy/</a
                  >
                </p>
                <h3 class="title">
                  Widerruf, Änderungen, Berichtigungen und Aktualisierungen
                </h3>
                <p>
                  Der Nutzer hat das Recht, auf Antrag unentgeltlich Auskunft zu
                  erhalten über die personenbezogenen Daten, die über ihn
                  gespeichert wurden. Zusätzlich hat der Nutzer das Recht auf
                  Berichtigung unrichtiger Daten, Sperrung und Löschung seiner
                  personenbezogenen Daten, soweit dem keine gesetzliche
                  Aufbewahrungspflicht entgegensteht.
                </p>
                <p>
                  Die Auskunft erfolgt auf Wunsch auf elektronischem Wege oder
                  schriftlich zur Abholung am Unternehmenssitz. Eine Zusendung
                  auf postalischem Wege ist gegen Kostenersatz möglich. Eine
                  unentgeltliche Auskunft ist einmal pro Kalenderjahr möglich,
                  jede weitere Auskunft kann gegen ein Entgelt erworben werden,
                  sofern keine nachweisbaren Hinweise auf unzulässigerweise oder
                  unrichtig gespeicherte Daten existieren.
                </p>
                <p>
                  Ihr Recht auf Widerruf und Korrektur können Sie gegenüber
                  folgender Anschrift geltend machen:
                </p>
                <p>
                  Jörg Pillar<br />
                  Unterer Sonnenberg 9<br />
                  07422 Bad Blankenburg<br />
                  <a href="mailto:info@pillarsoft.de" class="email"
                    >info@pillarsoft.de</a
                  >
                </p>
                <h3 class="title">
                  Recht auf Einschränkung der Verarbeitung, Datenübertragbarkeit
                  und Beschwerderecht bei einer Aufsichtsbehörde
                </h3>
                <p>
                  Der Nutzer hat das Recht auf die Einschränkung der
                  Verarbeitung der sich auf ihn beziehenden personengebundenen
                  Daten. Sofern anwendbar, hat der registrierte Besucher
                  Anspruch auf die Herausgabe der von ihm bereitgestellten Daten
                  in offenen standardisierten Datenformaten in komulierter Form,
                  die es ihm ermöglichen, diese Daten zu Konkurrenzprodukten zu
                  übertragen. Weiterhin hat der betroffene Nutzer des Angebotes
                  bei mutmaßlichen Verstößen gegen die gesetzliche Grundlage das
                  Recht, jederzeit Beschwerde bei der zuständigen
                  Aufsichtsbehörde einzureichen.
                </p>
                <h3 class="title">Widerspruchsrecht gegen die Verarbeitung</h3>
                <p>
                  <strong>
                    Gemäß Art. 21 DSGVO hat jede betroffene Person das Recht auf
                    Widersspruch gegen die Verarbeitung der auf sie bezogenen
                    perönlichen Daten, sofern die jeweiligen gesetzlichen
                    Vorraussetzungen vorliegen.
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div class="row bg-white justifyCenter">
            <div class="col-8 fs-4 p-4">
              <div class="normalerText">
                Bildnachweise:
              </div>
            </div>
          </div>
          <div class="row bg-white justifyCenter">
            <div class="col-8 fs-4 p-4">
              <p>Bild von <a href="https://pixabay.com/de/users/peggy_marco-1553824/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2309042">Peggy und Marco Lachmann-Anke</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2309042">Pixabay</a></p>
              <p>Bild von <a href="https://pixabay.com/de/users/mohamed_hassan-5229782/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3207895">mohamed Hassan</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3207895">Pixabay</a></p>
              <p>Bild von <a href="https://pixabay.com/de/users/mediamodifier-1567646/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2140606">Mediamodifier</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2140606">Pixabay</a></p>
              <p>Bild von <a href="https://pixabay.com/de/users/geralt-9301/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4025614">Gerd Altmann</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=4025614">Pixabay</a></p>
              <p>Bild von <a href="https://pixabay.com/de/users/ar130405-423602/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2082642">ar130405</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=2082642">Pixabay</a></p>
              <p>Bild von <a href="https://pixabay.com/de/users/quincecreative-1031690/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3562191">3D Animation Production Company</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3562191">Pixabay</a></p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="row bg-white pt-5 t-center">
          <div class="col-12 p-0 whi">
            <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
          </div>
        </div>
      </div>
      <div
        id="stickybar"
        class="hidden-xs wow slideInRight animated"
        data-wow-delay="2s"
        style="
          visibility: visible;
          -webkit-animation-delay: 2s;
          -moz-animation-delay: 2s;
          animation-delay: 2s;
        "
      >
        <ul>
          <li>
            <a
              href="kontakt/"
              class=""
              style="width: 70px; height: 70px; padding-top: 10px"
            >
              <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
              <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                >+49 36741 58 68 99</span
              >
            </a>
          </li>
          <li>
            <a
              href="mailto: info@pillarsoft.de"
              class=""
              style="width: 70px; height: 70px; padding-top: 10px"
              ><i
                class="far fa-envelope-open fa-2x"
                style="font-size: 1.3em"
              ></i>
              <span style="height: 70px; padding-top: 10px; margin-top: -10px">
                info@pillarsoft.de</span
              ></a
            >
          </li>
        </ul>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>
