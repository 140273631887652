<template>
  <Layout>
    <template #default>
      
        <div class="container-fluid m-0 p-0 ">
          <div class="row bg-white justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Webdesign</span>
          </div>          
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>

          <div class="row bg-white pt-3 justify-content-center">
            <div class="col-md-6 col-sm-10 whi ">
              <p>
                Websites sind heutzutage die häufigste Visualisierungsform von
                Marken.
              </p>
              <p>
                Eine schlechte, veraltete oder irreführende Website enttäuscht
                daher nicht nur Ihre Besucher.
              </p>
              <p>
                Sie richtet auch einen großen Imageschaden an und führt dazu,
                dass Ihr Geschäftserfolg hinter seinen Möglichkeiten
                zurückbleibt.
              </p>
              <p>
                Es gibt mittlerweile mehr Mobile Geräte, wie Handys, Smartphones
                und Tablets als PC's und Notebooks.
              </p>
              <p>
                Aber nur auf neueren Webseiten ist die Darstellung mit diesen
                Geräten möglich.
              </p>
            </div>
          </div>
          <div class="row bg-white p-5  t-center">
            <div class="col-12 p-0 whi">
              <button
                type="button"
                class="btn btn-outline-dark p-3"
                @click="$router.push('/Kontakt')"
              ><h2 class="fw-bolder">
                WIR helfen Ihnen!</h2>
              </button>
            </div>
          </div>

          <div class="row bg-white pt-2 justify-content-center">
            <div class="col-md-6 col-sm-10 whi">
              <p>
                Ihre Webseite ist schon ein paar Jahre alt und dem Design sieht
                man dies auch an?
              </p>
              <p>
                Der Stil ihres Unternehmensdesign hat sich weiter entwickelt.
                Ihr Webauftritt ist noch der alte?
              </p>
              <p>
                Wann haben Sie sich das letzte Mal ihren Webauftritt selbst
                angesehen? Hat es Ihnen gefallen?
              </p>
              <p>
                Entspricht der Webauftritt dem Design Ihrer Broschüren, Kataloge
                und Außenwerbung?
              </p>
            </div>
          </div>
          <div class="row bg-white p-5 t-center">
            <div class="col-12 p-0 whi">
              <button
                type="button"
                class="btn btn-outline-dark p-3"
                @click="$router.push('/Kontakt')"
              ><h2 class="fw-bolder">
                WIR helfen Ihnen!</h2>
              </button>
            </div>
          </div>

           <div class="row hintergrundHell justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Entwicklung</span>
          </div>          
          <div class="row pt-5 hintergrundHell justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>
          <div class="row hintergrundHell p-4 pt-5 justify-content-center">
            <div class="col-md-4 col-sm-10 whi ">
              <h2 class="mb-4">Website Entwicklung</h2>
              <p>
                Erhalten Sie Ihre Website innerhalb weniger Tage* ohne selbst zu
                designen.
              </p>
              <p>
                Mit unserer Unterstützung geht Ihr neuer, professionell
                gestaltete Webauftritt inklusive modernem, auf Ihr Unternehmen
                abgestimmtes Webdesign in kürzester Zeit online.
              </p>
              <h2  class="mb-4 pt-5">Website selbst erstellen? Geht auch einfacher!</h2>
              <p>
                Mit unserem Komplett-Service können Sie sich auf Ihr
                Tagesgeschäft konzentrieren.
              </p>
              <p>
                Sparen Sie Zeit, Kosten und Nerven, denn Sie haben besseres zu
                tun, als sich stundenlang mit komplizierten Tools zur
                Programmierung einer Website auseinanderzusetzen.                 
              
              </p>
              <p>                
                Konzentrieren Sie sich auf Ihr Geschäft.
              </p>
              <p >* Bei vorliegen aller notwendigen Daten</p>
            </div>
            <div class="col-4 bildschirnansicht">
              <img src="../assets/network.jpg" class="img-fluid mx-auto d-block" />
              
            </div>
          </div>
          <div class="row hintergrundHell p-4 pt-5 justify-content-center">
            <div class="col-8 whi t-center">
              <button
                type="button"
                class="btn btn-secondary p-3"
                @click="$router.push('/Kontakt')"
              >
                Für Ihre neue Website jetzt Kontakt aufnehmen
              </button>
            </div>
          </div>
            
          <div class="row bg-white justify-content-center pt-5">
            <span class="Abschnitt-titel2 t-center">Ihre Vorteile</span>
          </div>          
          <div class="row pt-5 bg-white justify-content-center">
            <div class="col-4 t-center p-4">
              <hr />
            </div>
          </div>     
          <div class="row bg-white pt-5 justify-content-center">
            <div class="col-md-4 col-sm-10 whi">
              <h4  class="mb-4">Professionelles Webdesign </h4>
              <ul>
                <li>Attraktives, responsives Webdesign</li>
                <li>Kontaktformular für Ihre Kunden</li>
                <li>Integration Ihrer Bilder oder Auswahl von Argenturbildern</li>
                <li>Mobil optimiert</li>
                <li>Schnelle Ladezeiten</li>
                <li>Flexibler Änderungsservice</li>
                <li>Auf Wunsch mit Hosting</li>
                <li>Einem Ansprechpartner direkt in Ihrer Region</li>
                <li>Beliebige weitere Funktionalität wie z.B. Kundenbereiche, Downloads oder Webshops möglich</li>
              </ul>
              
            </div>
            
            <div class="col-4 bildschirnansicht">
              <img src="../assets/technology.jpg" class="img-fluid mx-auto d-block" />            
            </div>

          </div>
          <div class="row bg-white pt-5 pb-5 justify-content-center">
            <div class="col-8 whi t-center">
              <button
                type="button"
                class="btn btn-secondary p-3"
                @click="$router.push('/Kontakt')"
              >
                Für Ihre neue Website jetzt Kontakt aufnehmen
              </button>
            </div>
          </div>
              





        </div>
        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i
                  class="far fa-envelope-open fa-2x"
                  style="font-size: 1.3em"
                ></i>
                <span
                  style="height: 70px; padding-top: 10px; margin-top: -10px"
                >
                info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
