<template>
  <Layout>
    <template #default>
      <div class="container-fluid m-0 p-0">
        <div class="row bg-white justify-content-center pt-5">
          <div class="col-4">
            <img src="../assets/qds.jpg" class="img-fluid mx-auto d-block" />
          </div>
        </div>

        <div class="row bg-white pt-5 justify-content-center bildschirnansicht">
          <div class="col-10 p-0 whi t-center">
            <h2>
              DOKUMENTATIONSSOFTWARE VON <span class="Pillar">PILLAR</span
              ><span class="Soft">SOFT</span>
            </h2>
          </div>
        </div>

        <div class="row pt-0 bg-white justify-content-center">
          <div class="col-8 t-center p-4">
            <p>
              <span>QDS</span> ist ein Web-basiertes System zur produktionsbegleitenden
              Dokumentation von Prüf- und Testergebnissen. Einfach in Ihrer vorhandenen
              Netzwerkstruktur zu etablieren, bietet es die Möglichkeit an jeden
              Arbeitsplatz egal ob PC oder Tablet zu arbeiten. Mitarbeiter und
              Arbeitsplatz bezogene Rechte ermöglichen eine einfache Administration von
              QDS.
            </p>
            <p>Über Barcodes kann jedes Teil in der Fertigung sicher erkannt werden.</p>
            <p>
              Organisieren Sie jeden weiteren Produktionsschritt an Hand von
              Prüfergebnisen. Leiten Sie einzelne Teile zur Nachbearbeitung um , wenn die
              Vorgaben nicht eingehalten wurden, oder Beenden Sie den Produktionsablauf
              für defekte Teile.
            </p>
            <P
              >Erhalten sie einen detailierten Überblick über die Prüfergebnisse jedes
              einzelnen Prüfpunktes und jeder einzelnen Tätigkeit.</P
            >
            <P
              >Reduzieren Sie teure Nacharbeiten und Ausschüsse durch Erkennung von
              Schwachstellen in Ihrem Produktionsablauf.</P
            >
          </div>
        </div>

        <div class="row pt-5 bg-white justify-content-center">
          <div class="col-3 t-center p-4">
            <img src="../assets/qds/modell.png" height="100" class="p-2" />
          </div>
          <div class="col-3 t-center p-4">
            <img src="../assets/qds/fertigung.png" height="100" class="p-2" />
          </div>
          <div class="col-3 t-center p-4">
            <img src="../assets/qds/taetigkeiten.png" height="100" class="p-2" />
          </div>
        </div>
        <div class="row bg-white justify-content-center">
          <div class="col-3 t-center p-2">
            <p>
              Für jedes Modell in Ihrer Fertigung können eigene Szenarien angelegt werden.
            </p>
            <p>Behalten Sie den Überblick über Ihre Fertigung und Qualitätsstandards</p>
          </div>
          <div class="col-3 t-center p-2">
            <p>
              Für jeden Fertigungsschritt können beliebig viele Prüfungen festgelegt
              werden.
            </p>
            <p>Entscheiden Sie über die Häufigkeit der Prüfung</p>
          </div>
          <div class="col-3 t-center p-2">
            <p>
              Erstellen Sie ihre Standartprüfverfahren fest und weisen sie diese
              Tätigkeiten und Modellen zu.
            </p>
            <p>
              Legen Sie Grenzwertde und Prüfvorgaben individuell für jedes Modell fest.
            </p>
          </div>
        </div>
        <div class="row pt-5 bg-white justify-content-center">
          <div class="col-8 text-center">
            <h2>Produktionsbegleitende Prüfungsfolgeplanung</h2>
          </div>
        </div>
        <div class="row pt-5 justify-content-center">
          <div class="col-4 text-center">
            <img
              src="../assets/qds/Produktionsbeginn.png"
              width="450"
              class="p-2 border"
            />
          </div>
          <div class="col-4 text-start">
            <ul>
              <li>Modellspezifische Planung</li>
              <li>Produktionsbegleitende Prüfungen</li>
              <li>Flusssteuerung bei Qualitätsmängel</li>
              <li>Detailübersicht der Schwachstellen in der Produktionskette</li>
              <li>
                Verschiedene Prüfhäufigkeiten
                <ul>
                  <li>
                    Immer Prüfen - Jedes Teil wird an diesem Produktionsschritt geprüft
                  </li>
                  <li>
                    regelmäßig Prüfen - es wird z.B. jedes 10. Teil an dieser Prüfstelle
                    geprüft
                  </li>
                  <li>
                    Stichpunktprüfung - es wird zufällig ein Teill innerhalb von z.B. 50
                    Produktionen geprüft
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="row pt-5 justify-content-center">
          <div class="col-8 text-center">
            <h2>Kostenreduzierung</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-4">
            <ul>
              <li>Umfangreiche Auswertungen</li>
              <li>Auffinden von Produktionsrisiken</li>
              <li>Reduzierung von Fehlproduktionen</li>
              <li>Minimierung von Ausschuss</li>
              <li>Motivationssteigerung der Mitarbeiter</li>
              <li>Steigerung des Gewinns</li>
            </ul>
          </div>
          <div class="col-4 text-center">
            <img src="../assets/qds/Ausschussreduzierung.png" width="450" class="p-2" />
          </div>
        </div>
        <div class="row pt-5 justify-content-center">
          <div class="col-8 text-center">
            <h2>Produktionsbegleitende Prüfungsfolgeplanung</h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-6 text-center">
            <img src="../assets/qds/zertifikat.png" height="400" class="p-2" />
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-3">
            <ul>
              <li>Prüfnachweise für Einzelteile</li>
              <li>Zertifikaterstellung als PDF</li>
              <li>Individuelles Design</li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-6 text-center">
            <video controls width="600" class="videoClassGross videosize">
              <source src="../assets/QDS.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
        <div class="row hintergrundHell pb-5 pt-5 justify-content-center">
          <div class="col-8 whi t-center">
            <button class="btn btn-outline-danger" @click="$router.push('/Kontakt')">
              Jetzt Kontakt aufnehmen
            </button>
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center bildschirnansicht">
          <div class="col-4 t-center border">
            <img src="../assets/man.jpg" class="img-fluid mx-auto d-block" />
          </div>
          <div class="col-4 p-3 whi">
            <h2>ZUM KOSTENLOSEN BERATUNGSGESPRÄCH</h2>
            <p>Haben Sie Fragen?</p>
            <p>
              Wollen Sie mehr erfahren oder benötigen Sie einen fachkundigen Rat zu Ihrem
              Softwareprojekt? Wir helfen Ihnen von Anfang an.
            </p>
            <p>Ihr persönlicher Ansprechpartner:</p>
            <p>Jörg Pillar <br />Tel: +49 173 68 39 084 <br />Mail: info@pillarsoft.de</p>
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center handyansicht">
          <div class="col-12 t-center border">
            <img src="../assets/man.jpg" class="img-fluid mx-auto d-block" />
          </div>
        </div>
        <div class="row bg-white pt-5 justify-content-center handyansicht"></div>
        <div class="row bg-white pt-5 justify-content-center handyansicht">
          <div class="col-10 p-3 whi">
            <h2>ZUM KOSTENLOSEN BERATUNGSGESPRÄCH</h2>
            <p>Haben Sie Fragen?</p>
            <p>
              Wollen Sie mehr erfahren oder benötigen Sie einen fachkundigen Rat zu Ihrem
              Softwareprojekt? Wir helfen Ihnen von Anfang an.
            </p>
            <p>Ihr persönlicher Ansprechpartner:</p>
            <p>Jörg Pillar <br />Tel: +49 173 68 39 084 <br />Mail: .de</p>
          </div>
        </div>

        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i class="far fa-envelope-open fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px">
                  info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
