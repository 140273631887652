<template>
  <div class="container ">
    <div class="row  space-between" >
      <div class="col-md-4">
        <router-link to="/" class="nav-link">
          <img
            src="../assets/pillarsoft.png"
            class="pillarsoft"
            alt="Logopillarsoft"
          />
        </router-link>
      </div>
      <div class="col-md-8 keinKlein">
        <nav
          class="navbar navbar-expand-lg  header_Justify"
        >
          <ul class="navbar-nav fw-bold header_Justify">
            <li class="nav-item active  ">              
              <router-link to="/" class="nav-link">
                  <i class="fas fa-home headerIcon" ></i><br/>START
              </router-link>
            </li>
            <li class="nav-item ">              
              <router-link to="/Software" class="nav-link">
                <i class="fas fa-chart-line headerIcon" ></i><br/>SOFTWARE
              </router-link>
            </li>
            <li class="nav-item ">              
              <router-link to="/QDS" class="nav-link">
                <i class="fas fa-file-invoice headerIcon" ></i><br/>QDS
              </router-link>
            </li>
            <li class="nav-item ">              
              <router-link to="/Web" class="nav-link">
                <i class="fas fa-pencil-ruler headerIcon" ></i><br/>WEBDESIGN
              </router-link>
            </li>            
            <li class="nav-item ">              
              <router-link to="/Shop" class="nav-link">
                <i class="fas fa-cart-plus headerIcon" ></i><br/>SHOPS
              </router-link>
            </li>           
            <li class="nav-item ">              
              <router-link to="/Certifikate" class="nav-link">
                <i class="fas fa-award headerIcon" ></i><br/>Certifikate
              </router-link>
            </li>
            <li class="nav-item ">
              <router-link to="/Kontakt" class="nav-link">
                <i class="far fa-envelope-open headerIcon" ></i><br/>KONTAKT
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
      <div class="col-md-8 keinGross">        
        <div class="row">         
          <div class="col-3  t-center ">  
              <router-link to="/Software" class="">
                <i class="fas fa-chart-line headerIcon" ></i><br/>SOFTWARE
              </router-link></div>
          <div class="col-3 t-center ">
              <router-link to="/Web" class="">
                <i class="fas fa-pencil-ruler headerIcon" ></i><br/>DESIGN
              </router-link></div>
          <div class="col-3 t-center ">
            <router-link to="/Shop" class="">
                <i class="fas fa-cart-plus headerIcon" ></i><br/>SHOPS
              </router-link></div>
          <div class="col-3 t-center ">
              <router-link to="/Kontakt" class="">
                <i class="far fa-envelope-open headerIcon" ></i><br/>KONTAKT
              </router-link></div>
        </div>
            
              
          
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" }),
  },
  methods: {},
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped></style>
