<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0 bgFoto">
          <div class="row justify-content-center">
            <div class="col-7 DES">
              <h1>Impressum</h1>
            </div>
          </div>          

      
          <div class="row bg-white pt-5 pb-5 border-bottom whi">
            <div class="col-4 p-0 t-center">
              
            </div>
            <div class="col-4 p-0 t-center">
              <h4 class="fw-bolder"></h4>
              <p>
                Jörg Pillar<br />
                Unterer Sonnenberg 9<br />
                07422 Bad Blankenburg
              </p>

              <p><i class="far fa-envelope-open pe-2"></i> info@pillarsoft.de</p>
              <p><i class="fas fa-phone pe-2"></i>036741 - 58 68 99</p>
              <p><i class="fas fa-fax pe-2"></i>036741 - 58 68 97</p>
              <p>Steuer-ID: DE815599352</p>
            </div>
            <div class="col-4 p-0 t-center">
              
            </div>
          </div>
          
          <div class="row bg-white pt-5 t-center">
            <div class="col-12 p-0 whi">
              <h2 class="fw-bolder">WIR helfen Ihnen!</h2>
            </div>
          </div>
        </div>
        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
          style="
            visibility: visible;
            -webkit-animation-delay: 2s;
            -moz-animation-delay: 2s;
            animation-delay: 2s;
          "
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 58 68 99</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i
                  class="far fa-envelope-open fa-2x"
                  style="font-size: 1.3em"
                ></i>
                <span
                  style="height: 70px; padding-top: 10px; margin-top: -10px"                 
                >
                info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";


export default {
  name: "Stammdaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped></style>



