<template>
  <div class="container">
    <div class="row  pt-3 border-bottom bildschirnansicht">
      <div class="col-4 p-0 t-center">
        <h4 class="fw-bolder">
          <router-link to="/Impressum" class="nav-link">Impressum</router-link>
        </h4>
      </div>
      <div class="col-4 p-0 t-center">
       
        <p>
          PILLARSOFT,
          Unterer Sonnenberg 9,
          07422 Bad Blankenburg
        </p>
        <p>
          <i class="far fa-envelope-open pe-4"> info@pillarsoft.de</i>
          <i class="fas fa-phone pe-4"> 036741 586899</i>
          <i class="fas fa-fax"> 036741 586897</i>
        </p>
      </div>
      <div class="col-4 p-0 t-center">
        <h4 class="fw-bolder">
          <router-link to="/Datenschutz" class="nav-link"
            >Datenschutz</router-link
          >
        </h4>
        
      </div>
    </div>
    
    <div class="row bg-white pt-3 border-bottom handyansicht">
      <div class="col-4 p-0 ">
        <h4 class="">
          <router-link to="/Impressum" class="">Impressum</router-link>
        </h4>
        <h4 class="">
          <router-link to="/Datenschutz" class=""
            >Datenschutz</router-link
          >
        </h4>
      </div>
      <div class="col-8 p-0 t-center">
       <p>
          PILLARSOFT         
        </p>
        <p>          
          Unterer Sonnenberg 9,          
        </p>
        <p>
          07422 Bad Blankenburg
        </p>
        <p>
          <i class="far fa-envelope-open pe-4"> info@pillarsoft.de</i>
          <i class="fas fa-phone pe-4"> 036741 - 58 68 99</i>
          <i class="fas fa-fax"> 036741 - 58 68 97</i>
        </p>
      </div>
      <div class="col-4 p-0 t-center">
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TheFooter",
  computed: {
    
  },
  methods: {},
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped></style>
